.wrapper {
    display: grid;
    grid-template: 1fr / 250px 1fr;
    height: 100vh;
    transition: grid-templaye-columns ease-in .2s;
}

.wrapperCollapsed {
    display: grid;
    grid-template: 1fr / 50px 1fr;
    height: 100vh;
}

.sidebar {
    overflow-y: visible;
    overflow-x: visible;
    height: 100vh;
}

.mainContent {
    height: 100vh;
}

.mainNav {
    position: fixed;
    height: 56px;
    z-index: 99;
    width: calc(100% - 250px);
}

.content {
    margin-top: 80px;
}
